<template>
  <div :class="[isNewUI ? 'layout-new-ui' : 'layout', classList, { 'pb-[40px]': includeBottomPadding }]">
    <transition name="fade">
      <CookieBanner
        v-if="showCookieBanner"
        @accept-cookie="acceptCookies"
        @reject-cookie="rejectCookies"
      />
    </transition>
    <slot />
  </div>
</template>

<script>
import AOS from 'aos';
import Cookies from 'js-cookie';

import CookieBanner from '@/Components/Global/CookieBanner.vue';
import userAttribution from '@/mixins/user-attribution.js';
import { trackEvent } from '@/Utils/analytics.js';
import { startGlobalTimer } from '@/Utils/designSwitcher.js';

export default {
  components: { CookieBanner },
  props: {
    classList: {
      type: String,
      default: 'bg-black',
    },
    includeBottomPadding: {
      type: Boolean,
      default: true,
    },
    isNewUI: {
      type: Boolean,
      default: false,
    },
    chatVersion: {
      type: [Number, String],
      required: false,
    }
  },
  mixins: [userAttribution],
  watch: {
    user: {
      handler(value) {
        if (!value) {
          return;
        }
        localStorage.setItem('user_email', value.email);
        localStorage.setItem('prod_user_id', value.id);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      campaignData: null,
      showCookieBanner: false,
    };
  },
  created() {
    this.saveParamsToLocalStorage();
    const accepted = Cookies.get('cookiesAccepted');
    let rejectedDate = '';

    if (typeof localStorage !== 'undefined') {
      rejectedDate = localStorage.getItem('cookiesRejectedDate');
    } else {
      console.warn('localStorage is not available');
    }

    if (!accepted) {
      if (rejectedDate) {
        const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
        const rejectedTime = new Date(rejectedDate).getTime();
        const currentTime = new Date().getTime();

        if (currentTime - rejectedTime > oneWeekInMs) {
          this.showCookieBanner = true;
          localStorage.removeItem('cookiesRejectedDate');
        }
      } else {
        this.showCookieBanner = true;
      }
    }
  },
  async mounted() {
    AOS.init();
    this.extractCampaignData();
    await this.attributeUser();

    this.sendCompleteRegistrationEvent();

    // eslint-disable-next-line vue/valid-next-tick
    await this.$nextTick(() => {
      startGlobalTimer();
    });
  },
  methods: {
    getQueryParams() {
      const params = {};
      const url = window.location.href;
      const queryString = url.split('?')[1] || '';
      const pairs = queryString.split('&');

      pairs.forEach((pair) => {
        const [key, value] = pair.split('=');
        if (key && value) {
          params[key] = decodeURIComponent(value);
        }
      });

      return params;
    },
    saveParamsToLocalStorage() {
      const existingParams = localStorage.getItem('urlParams');
      if (!existingParams) {
        const params = this.getQueryParams();
        if (Object.keys(params).length > 0) {
          localStorage.setItem('urlParams', JSON.stringify(params));
        }
      }
    },
    extractCampaignData() {
      const queryParams = this.getQueryParams();

      this.campaignData = {
        campaign_name:
          queryParams['campaign_name'] || queryParams['adgroupid'],
        campaign_id:
          queryParams['campaign_id'] || queryParams['utm_campaign'],
        ad_name: queryParams['ad_name'] || queryParams['utm_content'],
        utm_source: queryParams['utm_source'],
      };

      const identifyEvent = new amplitude.Identify();
      const currentRouteName = route().current();

      identifyEvent.set('environment', window.stage);
      identifyEvent.set('adGroup', this.campaignData.campaign_name);
      identifyEvent.set('campaign', this.campaignData.campaign_id);
      identifyEvent.set('creative', this.campaignData.ad_name);
      identifyEvent.set('network', this.campaignData.utm_source);
      identifyEvent.set('locale', window.locale);
      identifyEvent.set('platform', 'web');
      identifyEvent.set('version', this.chatVersion);

      if (currentRouteName.includes('onboarding')) {
        identifyEvent.set(
          'onboardingType',
          localStorage.getItem('onboarding_name')
        );
      }

      amplitude.identify(identifyEvent);
    },
    acceptCookies() {
      Cookies.set('cookiesAccepted', 'true', { expires: 365 });
      this.showCookieBanner = false;
    },
    rejectCookies() {
      Cookies.remove('cookiesAccepted');
      localStorage.setItem(
        'cookiesRejectedDate',
        new Date().toISOString()
      );
      this.showCookieBanner = false;
    },
    sendCompleteRegistrationEvent() {
      if (!this.isJustSignedUp) {
        if (this.isSocialAuth) {
          trackEvent('onboarding_login',
            {},
            'brevo',
            {
              'email': this.$page.props.auth.user.email
            }
          );
        }
        return;
      }
      trackEvent('onboarding_signin',
        {},
        'brevo',
        {
          'email': this.user.email
        }
      );
      this.$fbq.event('CompleteRegistration', {}, {
        eventID: this.$fbq.generateGeneralEventId('CompleteRegistration'),
      });

      localStorage.setItem('prod_user_id', this.$page.props.auth.user.id);
      localStorage.setItem('cr_ts', Date.now());

      Cookies.remove('social_auth');
      Cookies.remove('just_signed_up');
    },
  },
  computed: {
    isJustSignedUp() {
      return !!Cookies.get('just_signed_up');
    },
    isSocialAuth() {
      return !!Cookies.get('social_auth');
    },
    user() {
      return this.$page.props.auth.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  @apply min-h-full flex flex-col relative;
}

.layout-new-ui {
  @apply box-content flex flex-col w-full bg-[--background-primary];
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}

.fade-enter-to,
.fade-leave-from {
  @apply opacity-100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
