<template>
  <div class="cookie-banner">
    <div class="cookie-banner__block">
      <p class="cookie-banner__title">{{ $t('cookieBanner.title') }}</p>
      <div class="cookie-banner__description">
        {{ $t('cookieBanner.description') }}
      </div>
    </div>
    <div class="cookie-banner__buttons">
      <button
        type="button"
        data-test="cookie-accept-button"
        class="cookie-banner__button accept-button"
        @click="$emit('accept-cookie')"
      >
        {{ $t('cookieBanner.acceptAllBtn') }}
      </button>
      <button
        type="button"
        class="cookie-banner__button reject-button"
        @click="$emit('reject-cookie')"
      >
        {{ $t('cookieBanner.rejectAllBtn') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cookie-banner {
    @apply fixed z-[1000] bottom-[70px] right-[50%] flex flex-col max-w-[348px] w-full px-[20px] py-[24px] rounded-[20px] bg-[#2E2E2E];
    transform: translate(50%, -20%);

    @screen lg {
        @apply p-[24px] bottom-[24px] left-1/2 flex-row gap-[24px] max-w-[900px];
        transform: translate(-50%, 0);
    }
}

.cookie-banner__block {
    @apply flex flex-col;
}

.cookie-banner__title {
    @apply mb-[14px] font-[600] text-[16px] leading-[19px] text-[#FFFFFF];
}

.cookie-banner__description {
    @apply mb-[24px] font-[500] text-[14px] leading-[18px] text-[#FFFFFF];

    @screen lg {
        @apply mb-0;
    }
}

.cookie-banner__buttons {
    @apply flex justify-between gap-[12px];

    @screen lg {
        @apply items-end;
    }
}

.cookie-banner__button {
    @apply w-full py-[10px] rounded-[16px] font-[500] text-[16px] leading-[19px] text-[#FFFFFF];

    @screen lg {
        @apply w-[146px] h-[40px];
    }
}

.accept-button {
    @apply bg-[#009B78];
    &:hover {
        @apply bg-[#007A5E];
    }
}

.reject-button {
    @apply bg-[#626262];
    &:hover {
        @apply bg-[#515151];
    }
}
</style>
